<template>
  <div id="app" style="max-width: 100vw; overflow-x: hidden;">
    <NavCustom></NavCustom>
    <div id="fb-root"></div>
    <router-view/>
    <FooterCustom></FooterCustom>
    <div class="fb-customerchat"
         attribution="install_email"
         attribution_version="biz_inbox"
         page_id="784997661847539">
    </div>
  </div>
</template>

<style lang="scss">
@import "~bulma/sass/utilities/_all";

$primary: #DA2565;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);

$gold: #F2BC1E;
$gold-light: findLightColor($gold);
$gold-dark: findDarkColor($gold);
$gold-invert: findColorInvert($gold);

$yellow: $gold;
$yellow-light: findLightColor($yellow);
$yellow-dark: findDarkColor($yellow);
$yellow-invert: findColorInvert($yellow);

$green: #96E8BC;
$green-light: findLightColor($green);
$green-dark: findDarkColor($green);
$green-invert: findColorInvert($green);

$link: #081444;
$link-invert: findColorInvert($link);
$link-dark: findDarkColor($link);
$link-light: findLightColor($link);
$link-focus-border: $link;

$white: #ffffff;
$white-invert: findColorInvert($white);
$white-dark: findDarkColor($white);
$white-light: findLightColor($white);

$off-white: #fffbf2;
$off-white-invert: findColorInvert($off-white);
$off-white-dark: findDarkColor($off-white);
$off-white-light: findLightColor($off-white);

$ghost-white: #fafaff;
$ghost-white-invert: findColorInvert($ghost-white);
$ghost-white-dark: findDarkColor($ghost-white);
$ghost-white-light: findLightColor($ghost-white);

$light-blue: #2CB3F3;
$light-blue-invert: findColorInvert($light-blue);
$light-blue-dark: findDarkColor($light-blue);
$light-blue-light: findLightColor($light-blue);

$custom-colors: null !default;
$custom-shades: null !default;

$colors: mergeColorMaps(
  (
    "primary": (
      $primary,
      $primary-invert,
      $primary-light,
      $primary-dark,
    ),
    "gold": (
      $gold,
      $gold-invert,
      $gold-light,
      $gold-dark,
    ),
    "yellow": (
      $yellow,
      $yellow-invert,
      $yellow-light,
      $yellow-dark,
    ),
    "green": (
      $green,
      $green-invert,
      $green-light,
      $green-dark,
    ),
    "link": (
      $link,
      $link-invert,
      $link-light,
      $link-dark,
    ),
    "white": (
        $white,
        $white-invert,
        $white-light,
        $white-dark,
    ),
    "off-white": (
        $off-white,
        $off-white-invert,
        $off-white-light,
        $off-white-dark,
    ),
    "ghost-white": (
        $ghost-white,
        $ghost-white-invert,
        $ghost-white-light,
        $ghost-white-dark,
    ),
    "light-blue": (
      $light-blue,
      $light-blue-invert,
      $light-blue-light,
      $light-blue-dark
    )
  ),
  $custom-colors
);

@import url('https://fonts.googleapis.com/css2?family=Alatsi&family=League+Spartan:wght@400;700&family=Poppins&display=swap');

#app {
  font-family: 'Poppins', sans-serif !important;
}

.title, .button {
  font-family: 'Alatsi', sans-serif;
}

.heading {
  font-family: 'League Spartan', sans-serif;
  font-weight: 700;
}

$title-color: $primary;
$title-weight: 400;

.tag {
  font-size: 10px !important;
}

$navbar-item-img-max-height: 40px;

$tabs-link-focus-active-border-bottom-color: $primary;

@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>

<script>
import NavCustom from "./components/NavCustom";
import FooterCustom from "./components/FooterCustom";
export default {
  components: {FooterCustom, NavCustom},
  mounted() {
    window.fbAsyncInit = function() {
      FB.init({
        xfbml            : true,
        version          : 'v12.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }
}
</script>
