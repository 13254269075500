<template>
  <b-navbar fixed-top shadow style="max-width: 100vw; font-family: 'League Spartan', sans-serif; font-weight: 700; text-transform: uppercase">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
            class="logo-img"
            src="../assets/logo-nav.png"
            alt="Sibol: Ateneo Law School Career Fair 2024"
        >
      </b-navbar-item>
    </template>

    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/private-firms' }">
        Private Firms
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/list/ngo' }">
        NGOs
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/list/gov' }">
        Government
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/list/all' }">
        All Firms
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="router-link" :to="'/about'">
        About
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="'/faqs'">
        FAQs
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="'/contact'">
        Contact
      </b-navbar-item>
      <b-navbar-item tag="a" href="https://www.ateneo.edu/law/programs/jd/apprenticeship" target="_blank">
        Directory
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "NavCustom"
}
</script>

<style scoped>
  .logo-img {
    padding: 4px;
  }
</style>